a{
    text-decoration: none;
    color: #000;
}
input{
    display: block;
    border: 1px solid;
    border-radius: 5px;
    width: 95%;
    padding: 10px 12px;
    outline: none;
}

button{
    width: 200px;
    background-color: #3772ff;
    border: 1px solid;
    cursor: pointer;
    border-radius: 10px;
    padding: 12px 15px;
    bottom: 20px;
    color: #fff;
    border: none;

    &:hover{
        transition: all 0.3s linear;
        box-shadow: 1px 1px 13px rgb(149, 147, 147);
    }
}

.password_input{
    position: relative;
    display: flex;
    align-items: center;
    
}
.togglePass{
    position: absolute;
    font-size: 14px;
    right: 25px;
    top: 5px;
    background-color: transparent;
    padding: 7px 7px 10px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    width: 50px;
}
.page-content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.register{
    padding-bottom: 100px;
}
.auth{
    display: flex;
    
    img{
        flex: 1;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
    
    .auth_input{
        flex: 1;
        margin-top: 150px;
        text-align: center;
        
        form{
            width: 60%;
            margin: auto;

            h2{
                margin-bottom: 50px;
            }
            input{
                margin-bottom: 20px;
            }
            p{
                margin-top: 10px;
                font-size: 14px;
            }
            .signinWith{
                display: flex;
                justify-content: center;
                align-items: center;

                p{
                    border: 1px solid;
                    border-radius: 50%;
                    padding: 10px 15px;

                    &:nth-child(1){
                        margin-right: 20px;
                    }
                }
            }
        }        
        p{
            margin-top: 10px;
            font-size: 14px;
        }
    }
    .dont_ a{
        color: blue;
        padding-left: 5px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1139px) {
    .cover{

        img{
            height: 300px;
        }
    }
    .auth{
        justify-content: center;
        flex-direction: column;
        padding: 50px 0;

        img{
            width: 200px;
            margin: 50px auto;
        }
            
        .auth_input{
            margin-top: 0px;
        }
    }
}
@media screen and (max-width: 767px) {
    .cover{
        flex-direction: column-reverse;
        padding: 0;
        justify-content: center;

        div{
            margin-top: 50px;
            text-align: center;
        
            p{
                color: #fff;
                
    
                &:nth-child(1){
                    font-size: 40px;
                    font-weight: 500;
                }
                &:nth-child(2){
                    font-size: 40px;
                    font-weight: bolder;
                    color: yellow;
                }
                &:last-child{
                    width: auto;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        img{
            height: 150px;
        }
    }
    .auth{
        justify-content: center;
        flex-direction: column;
        padding: 50px 0;
    
        img{
            width: 200px;
            margin: 50px auto;
        }
            
        .auth_input{
            margin-top: 0px;
            
            form{
                width: 90%;
                margin: auto;
            }
        }
    }
}